
import { Vue, Options } from "vue-class-component";
import OutPackages from "./out-packages.vue";
import InPackages from "./in-packages.vue";
import AdvanceFilter from "./components/advance-filter.vue";
import debounce from "lodash/debounce";
import { TrackDeliveryController } from "@/app/ui/controllers/TrackDeliveryController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";

@Options({
  components: {
    OutPackages,
    InPackages,
    AdvanceFilter
  },
  computed: {}
})
export default class ActiveDelivery extends Vue {
  created() {
    this.setDefaultFilter();
  }

  setDefaultFilter() {
    let defaultQueryObject: any = "";
    if (this.$route.query?.q) {
      const urlParams = new URLSearchParams(atob(String(this.$route.query.q)));
      defaultQueryObject = Object.fromEntries(urlParams.entries());
    }
    const dashboardType =
      process.env.VUE_APP_IS_SENDER === "true"
        ? "sender-dashboard"
        : "corporate-dashboard";
    TrackDeliveryController.setPath(
      this.$route.name === "performa-pengiriman"
        ? `${dashboardType}/performance/list`
        : "stt/tracking/list"
    );
    TrackDeliveryController.setDefaultFilter(defaultQueryObject);
  }

  tabKeyActive = "paket-kiriman-anda";
  tabs = [
    {
      key: "paket-kiriman-anda",
      title: "Paket Kiriman Anda"
    },
    {
      key: "paket-untuk-anda",
      title: "Paket Untuk Anda"
    }
  ];

  get routerName() {
    return this.$route.name;
  }

  changeTab(key: string) {
    TrackDeliveryController.setStatusCategory(this.$route.name);
    TrackDeliveryController.setIsFromMe(key === "paket-kiriman-anda" ?? false);
    TrackDeliveryController.setConsignePhone(
      key === "paket-untuk-anda"
        ? AccountController.accountData.phone_number
        : ""
    );
    this.tabKeyActive = key;
    this.setDefaultFilter();
    this.onResetDateRange();

    if (IS_SENDER_ACCOUNT) {
      const eventFirstName =
        this.routerName === "pengiriman-aktif"
          ? "pengiriman_aktif"
          : "riwayat_pengiriman";
      const eventLastName =
        key === "paket-kiriman-anda"
          ? "paket_kiriman_anda"
          : "paket_untuk_anda";

      GTMCommonEvent(`sender_${eventFirstName}_${eventLastName}`);
    }
  }

  isTabActive(key: string) {
    return key === this.tabKeyActive;
  }

  // search resi
  get searchValue() {
    return TrackDeliveryController.search;
  }

  onSearch(value: string) {
    if (value.length > 3 || value.length === 0) {
      TrackDeliveryController.onSearchValue(value);
    }
  }

  clearSearch() {
    TrackDeliveryController.onSearchValue("");
  }

  // date range
  get startDate() {
    return TrackDeliveryController.dateRange.startDate;
  }
  get endDate() {
    return TrackDeliveryController.dateRange.endDate;
  }

  setPeriode(value: Array<any>) {
    if (value[0] && value[1]) {
      TrackDeliveryController.setStartDate(value[0]);
      TrackDeliveryController.setEndDate(value[1]);
      setTimeout(() => {
        TrackDeliveryController.setStatusCategory(this.$route.name);
        TrackDeliveryController.setIsFromMe(
          this.tabKeyActive === "paket-kiriman-anda" ?? false
        );
        TrackDeliveryController.setFirstPage();
        value[0] && value[1] && this.onRequest();
      }, 100);
    }
  }

  onResetDateRange() {
    const refs: any = this.$refs?.dateRangeFilter;
    refs.dates = {
      start: this.startDate,
      end: this.endDate
    };
    refs.onDateSelected();
  }

  get maxDateRange() {
    return new Date();
  }

  async onRequest() {
    await TrackDeliveryController.initDataTrackingList();
  }

  async fetchDataPackages() {
    await TrackDeliveryController.fetchTryDataPackages();
  }

  // advance filter
  onChangeAdvancedFilter = debounce(event => {
    TrackDeliveryController.setAdvanceFilterData(event);
    this.onRequest();
  }, 500);
}
