
import { Options, Vue, prop } from "vue-class-component";
import Checkbox from "primevue/checkbox";
import { TrackDeliveryController } from "@/app/ui/controllers/TrackDeliveryController";
import LpRadio from "@/app/ui/components/lp-radio/index.vue";
import {
  codOptions,
  codLabels,
  performanceOptions,
  performanceLabels,
  deliveryStatusesPerformanceDelivery,
  deliveryStatusesActiveDelivery,
  deliveryStatusesHistoryDelivery
} from "../modules/index";
import { ProductSender } from "@/domain/entities/Product";
import { ProductController } from "@/app/ui/controllers/ProductController";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

class Prop {
  isDisabled = prop<boolean>({
    type: Boolean,
    default: false
  });
  routerName = prop<string>({
    type: String,
    default: "pengiriman-aktif"
  });
}

@Options({
  emits: ["change", "update:modelValue"],
  components: {
    Checkbox,
    LpRadio
  }
})
export default class AdvanceFilter extends Vue.with(Prop) {
  mounted(): void {
    this.getProductSenderList();
  }
  isChevron = false;
  openFilter = false;
  productOptions: string[] = [];
  async getProductSenderList(): Promise<void> {
    const res: ResponsePayloadV2 = await ProductController.getProductSenderList(
      new ApiRequestList({
        page: 1,
        limit: 100,
        status: "active"
      })
    );
    if (res.data.length) {
      this.productOptions = res.data.map(
        (item: ProductSender) => item.productTypeCode
      );
    }
  }
  codOptions = codOptions;
  codLabels = codLabels;
  performanceOptions = performanceOptions;
  performanceLabels = performanceLabels;

  get filterData() {
    return TrackDeliveryController.advanceFilterData;
  }

  async resetFilter() {
    TrackDeliveryController.setDefaultAdvanceFilter();
    await TrackDeliveryController.initDataTrackingList();
  }

  get sumFilter() {
    return (
      TrackDeliveryController.advanceFilterData.status.length +
      TrackDeliveryController.advanceFilterData.product.length +
      (TrackDeliveryController.advanceFilterData.codType.length > 0 ? 1 : 0) +
      (TrackDeliveryController.advanceFilterData.statusPerformance.length > 0 &&
      this.isPerformaPengiriman
        ? 1
        : 0)
    );
  }

  onChange() {
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  get deliveryStatuses() {
    const status: any = {
      "pengiriman-aktif": deliveryStatusesActiveDelivery,
      "riwayat-pengiriman": deliveryStatusesHistoryDelivery,
      "performa-pengiriman": deliveryStatusesPerformanceDelivery
    };

    return status[this.routerName];
  }

  get isPerformaPengiriman() {
    return this.routerName === "performa-pengiriman";
  }
}
