import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "\n      bg-white\n      flex flex-col\n      text-12px\n      xxl:text-14px\n      overflow-hidden\n      h-screen\n      relative\n    " }
const _hoisted_2 = { class: "flex mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_TimeRangePicker = _resolveComponent("TimeRangePicker")!
  const _component_advance_filter = _resolveComponent("advance-filter")!
  const _component_OutPackages = _resolveComponent("OutPackages")!
  const _component_InPackages = _resolveComponent("InPackages")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createBlock("div", {
          key: tab.key,
          class: 
          `
          rounded-full
          text-10px
          xxl:text-14px
          py-2
          px-3
          mr-2
          ${
            _ctx.isTabActive(tab.key)
              ? 'bg-red-lp-100 text-white'
              : 'bg-white border border-red-lp-100 text-red-lp-100'
          }
          cursor-pointer
        `
        ,
          onClick: ($event: any) => (_ctx.changeTab(tab.key))
        }, _toDisplayString(tab.title), 11, ["onClick"]))
      }), 128)),
      _createVNode(_component_Search, {
        onInput: _ctx.onSearch,
        onClear: _ctx.clearSearch,
        value: _ctx.searchValue,
        placeholder: "Cari No. Resi",
        class: "pl-10"
      }, null, 8, ["onInput", "onClear", "value"]),
      _createVNode(_component_TimeRangePicker, {
        class: "mr-2",
        onSelect: _ctx.setPeriode,
        ref: "dateRangeFilter",
        advanceStyle: {
          fontSize: 'text-sm'
        },
        maxDate: _ctx.maxDateRange,
        startDate: _ctx.startDate,
        endDate: _ctx.endDate,
        isCloseDateAfterSelect: "",
        showOptions: "custom, hari ini, 7 hari terakhir, bulan ini",
        isConvertName: true
      }, null, 8, ["onSelect", "maxDate", "startDate", "endDate"]),
      _createVNode(_component_advance_filter, {
        routerName: _ctx.routerName,
        onChange: _ctx.onChangeAdvancedFilter
      }, null, 8, ["routerName", "onChange"])
    ]),
    (_ctx.tabKeyActive === 'paket-kiriman-anda')
      ? (_openBlock(), _createBlock(_component_OutPackages, {
          key: 0,
          routerName: _ctx.routerName,
          onFetchDataPackages: _ctx.fetchDataPackages
        }, null, 8, ["routerName", "onFetchDataPackages"]))
      : (_openBlock(), _createBlock(_component_InPackages, {
          key: 1,
          onFetchDataPackages: _ctx.fetchDataPackages
        }, null, 8, ["onFetchDataPackages"]))
  ]))
}