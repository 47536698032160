
import { Vue, Options, prop } from "vue-class-component";
import { TrackDeliveryController } from "@/app/ui/controllers/TrackDeliveryController";
import { TrackDeliveryData } from "@/domain/entities/TrackDelivery";
import {
  formatPrice,
  IS_SENDER_ACCOUNT,
  IS_ENABLE_CLAIM,
  IS_ENABLE_CLAIM_PENGIRIMAN_AKTIF,
  IS_ENABLE_CLAIM_RIWAYAT_PENGIRIMAN,
  checkRolePermission
} from "@/app/infrastructures/misc/Utils";
import { TRACK_DELIVERY } from "@/app/infrastructures/misc/RolePermission";
import FormClaim from "./components/form-claim.vue";
import { isCustomerApps, isProductAbleClaim, isSTTRetail } from "./modules";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";

class Prop {
  routerName = prop<string>({
    default: "pengiriman-aktif",
    type: String
  });
}

@Options({
  emits: ["fetchDataPackages"],
  components: {
    FormClaim
  },
  computed: {
    isDetailAble() {
      return IS_SENDER_ACCOUNT ?? checkRolePermission(TRACK_DELIVERY.DETAIL);
    }
  }
})
export default class InPackages extends Vue.with(Prop) {
  get isLoading() {
    return TrackDeliveryController.isLoading;
  }

  get isError() {
    return TrackDeliveryController.isError;
  }

  get errorCause() {
    return TrackDeliveryController.errorCause;
  }

  get dataPackages() {
    return TrackDeliveryController.trackDeliveryPackages.data;
  }

  get firstRequest() {
    return TrackDeliveryController.firstRequest;
  }

  get isAbleToClaimBasedOnEnv() {
    if (IS_ENABLE_CLAIM) {
      return true;
    } else if (this.$route.name === "pengiriman-aktif") {
      return IS_ENABLE_CLAIM_PENGIRIMAN_AKTIF;
    } else if (this.$route.name === "riwayat-pengiriman") {
      return IS_ENABLE_CLAIM_RIWAYAT_PENGIRIMAN;
    } else {
      return false;
    }
  }

  isClaimedAndClaimAble(isClaimed: boolean, isClaimAble: boolean) {
    return isClaimed && isClaimAble;
  }

  isNotClaimedAndClaimAble(isClaimed: boolean, isClaimAble: boolean) {
    return !isClaimed && isClaimAble;
  }

  get columns() {
    const tempColumns: Array<any> = [
      {
        name: "STT/No. Resi",
        key: "noResi",
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: TrackDeliveryData) => {
          if (item.isCod) {
            return `
            <div class='absolute' style='margin-top: -30px; margin-left: -15px;'>
              <img src='${require("@/app/ui/assets/svg/cod-label.svg")}' alt="label-float" />
            </div>
            <span class='text-left text-black-lp-300 flex justify-center pl-5'>${
              item.noResi
            }</span>
          `;
          }
          return `<span class='text-left text-black-lp-300 flex justify-center'>${item.noResi}</span>`;
        }
      },
      {
        name: "Shipment ID",
        key: "shipmentId",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (item: TrackDeliveryData) => {
          return `<span class='text-left text-black-lp-300 flex justify-center'>${item.shipmentId}</span>`;
        }
      },
      {
        name: "Berat Paket",
        key: "chargeableWeight",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (item: TrackDeliveryData) => {
          return `<span class='text-left text-black-lp-300 flex justify-center font-bold'>${item.chargeableWeight} Kg</span>`;
        }
      },
      {
        name: "Biaya Kirim",
        key: "totalAmount",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (item: TrackDeliveryData) => {
          if (item.isPromoRetail) {
            return `<div>
              <span class='text-left text-gray-lp-600 flex justify-center font-normal line-through'>${formatPrice(
                item.totalTariffBeforeDiscount
              )}</span>
              <span class='text-left text-black-lp-300 flex justify-center font-bold'>${formatPrice(
                item.totalTariffAfterDiscount
              )}</span>
            </div>`;
          }
          return `<span class='text-left text-black-lp-300 flex justify-center font-bold'>${formatPrice(
            item.totalAmount
          )}</span>`;
        }
      },
      {
        name: "Detail Penerima",
        key: "consigne",
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: TrackDeliveryData) => {
          return `<div class='flex text-black-lp-300 break-all'>${item.consigneName}</div>
        <div class='flex text-gray-lp-500 text-14px'>${item.consigneAddress}</div>`;
        }
      },
      {
        name: "Status Paket",
        key: "status",
        styleHead: "w-2/20 text-center whitespace-no-wrap",
        render: (item: TrackDeliveryData) => {
          if (item.status === "POD") {
            return `<div class="flex text-center font-medium capitalize">
                    <div class="rounded-full px-2 py-0 bg-green-lp-100 text-white">${item.status}</div>
                  </div>`;
          }
          return `<div class="flex font-medium text-center capitalize">
                    <div class="rounded-full px-2 py-0 bg-yellow-lp-100 text-white">${item.status}</div>
                </div>`;
        }
      }
    ];
    this.deliveryPerformanceColumn(tempColumns);
    if (
      this.isAbleToClaimBasedOnEnv &&
      this.routerName !== "performa-pengiriman"
    ) {
      this.ableToClaimBasedOnEnvColumn(tempColumns);
    }
    return tempColumns;
  }

  // make a claim
  isClaimForm = false;

  onClickRow(item: any) {
    this.$router.push(`/lacak-pengiriman/${item.sttId}`);
  }

  deliveryPerformanceColumn(column: Array<any>) {
    if (this.routerName === "performa-pengiriman") {
      column.push({
        name: "Performa Pengiriman",
        key: "status-performa",
        styleHead: "w-2/20 text-center whitespace-no-wrap",
        render: (item: TrackDeliveryData) => {
          if (item.statusPerformance === "Terlambat") {
            return `<div class="flex text-center font-medium capitalize">
                    <div class="rounded-full px-2 py-0 bg-pink-lp-100 text-white">${item.statusPerformance}</div>
                  </div>`;
          }
          return `<div class="flex font-medium text-center capitalize">
                    <div class="rounded-full px-2 py-0 bg-green-lp-900 text-white">${item.statusPerformance}</div>
                </div>`;
        }
      });
    }
  }

  ableToClaimBasedOnEnvColumn(column: Array<any>) {
    column.push(
      {
        name: "No Klaim",
        key: "noKlaim",
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: TrackDeliveryData) => {
          if (item.claimNo) {
            return `<span class='text-left text-red-lp-100 flex justify-center'>
              <a href='https://lionparcel.com/klaim/track' target='_blank'>${item.claimNo}</a>
            </span>`;
          }
          return `<span class='text-left flex justify-center'>-</span>`;
        },
        toolTip: (item: TrackDeliveryData) => {
          if (
            (isCustomerApps(item.shipmentId) || isSTTRetail(item.noResi)) &&
            isProductAbleClaim(item.product) &&
            this.isClaimedAndClaimAble(item.isClaimed, item.isClaimAble) &&
            item.claimNo
          ) {
            return `<div class='flex text-black-lp-300 text-sm'>Cek status klaim di menu Klaim <br /> atau Lacak Klaim paket Lion Parcel</div>`;
          }
        }
      },
      {
        name: "Klaim Paket",
        key: "button_column",
        styleHead: "w-2/20 text-left",
        styleButton: (item: TrackDeliveryData) => {
          if (
            (isCustomerApps(item.shipmentId) || isSTTRetail(item.noResi)) &&
            isProductAbleClaim(item.product) &&
            this.isNotClaimedAndClaimAble(item.isClaimed, item.isClaimAble)
          ) {
            return {
              outline: true,
              title: "Ajukan Klaim",
              textColor: "red-lp-100",
              color: "red-lp-100",
              class: "text-center",
              small: true,
              clickFunction: () => {
                this.getDetailStt(item.sttId);
              },
              chip: ""
            };
          } else if (
            this.isClaimedAndClaimAble(item.isClaimed, item.isClaimAble)
          ) {
            return {
              chip: `<div class="flex text-center font-medium capitalize">
                <div class="rounded-full px-2 py-0 bg-purple-lp-700 text-white">Klaim diajukan</div>
              </div>`
            };
          }
          return {
            chip: `<div>-</div>`
          };
        }
      }
    );
  }

  getDetailStt(id: number) {
    this.$router.push(
      `/lacak-pengiriman/${id}/ajukan-klaim?track=${this.routerName}&type=paket-untuk-anda`
    );

    GTMCommonEvent("sender_ajukan_klaim_request");
  }

  get pagination() {
    return TrackDeliveryController.trackDeliveryPackages.pagination;
  }

  fetchDataPackages() {
    this.$emit("fetchDataPackages");
  }
}
